import { combineReducers } from "redux";
import { authReducer } from "./auth/authReducer";
import { profileReducer } from "./auth/profileReducer";
import { cmsReducer } from "./cms/cmsReducer";
import { categoryReducer } from "./category/categoryReducer";
import { userReducer } from "./user/userReducer";
import { placeReducer } from "./place/placeReducer";
import { eventReducer } from "./event/eventReducer";
import { queryReducer } from "./query/queryReducer";
import { feedbackReducer } from "./feedback/feedbackReducer";
import loadingReducer from "./loading/loadingReducer";
import { accommodationReducer } from "./accommondation/accommodationReducer";
import { adminReducer } from "./admin/adminReducer";
import { foodReducer } from "./food/foodReducer";
import { bannerReducer } from "./banner/bannerReducer";
import { shopListReducer } from "./shop/shopListReducer";
import { dashboardReducer } from "./dashboard/dashboardReducer";
export const reducers = combineReducers({
  // auth
  auth: authReducer,
  profileReducer: profileReducer,
  cmsReducer: cmsReducer,
  categoryReducer: categoryReducer,
  userReducer: userReducer,
  placeReducer: placeReducer,
  eventReducer: eventReducer,
  queryReducer: queryReducer,
  feedbackReducer: feedbackReducer,
  loadingReducer: loadingReducer,
  accommodationReducer: accommodationReducer,
  adminReducer: adminReducer,
  foodReducer: foodReducer,
  bannerReducer: bannerReducer,
  shopListReducer: shopListReducer,
  dashboardReducer: dashboardReducer,
});
