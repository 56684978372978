export const adminTypes = {
  // Manage Dashboard

  GET_ADMIN_DASHBOARD_DATA_SUCCESS: "GET_ADMIN_DASHBOARD_DATA_SUCCESS",
  GET_ADMIN_DASHBOARD_DATA_PENDING: "GET_ADMIN_DASHBOARD_DATA_PENDING",
  GET_ADMIN_DASHBOARD_DATA_FAILED: "GET_ADMIN_DASHBOARD_DATA_FAILED",

  // profile data
  GET_PROFILE_DATA_PENDING: "GET_PROFILE_DATA_PENDING",
  GET_PROFILE_DATA_SUCCESS: "GET_PROFILE_DATA_SUCCESS",
  GET_PROFILE_DATA_FAILED: "GET_PROFILE_DATA_FAILED",

  // CMS data
  GET_CMS_DATA_PENDING: "GET_CMS_DATA_PENDING",
  GET_CMS_DATA_SUCCESS: "GET_CMS_DATA_SUCCESS",
  GET_CMS_DATA_FAILED: "GET_CMS_DATA_FAILED",

  // Page data
  GET_PAGE_DATA_PENDING: "GET_PAGE_DATA_PENDING",
  GET_PAGE_DATA_SUCCESS: "GET_PAGE_DATA_SUCCESS",
  GET_PAGE_DATA_FAILED: "GET_PAGE_DATA_FAILED",

  // Category data
  GET_CATEGORY_DATA_PENDING: "GET_CATEGORY_DATA_PENDING",
  GET_CATEGORY_DATA_SUCCESS: "GET_CATEGORY_DATA_SUCCESS",
  GET_CATEGORY_DATA_FAILED: "GET_CATEGORY_DATA_FAILED",
  GET_TILE_CATEGORY_DATA_PENDING: "GET_TILE_CATEGORY_DATA_PENDING",
  GET_TILE_CATEGORY_DATA_SUCCESS: "GET_TILE_CATEGORY_DATA_SUCCESS",
  GET_TILE_CATEGORY_DATA_FAILED: "GET_TILE_CATEGORY_DATA_FAILED",

  //Category Page data
  GET_CATEGORY_DETAIL_PENDING: "GET_CATEGORY_DETAIL_PENDING",
  GET_CATEGORY_DETAIL_SUCCESS: "GET_CATEGORY_DETAIL_SUCCESS",
  GET_CATEGORY_DETAIL_FAILED: "GET_CATEGORY_DETAIL_FAILED",

  // User data
  GET_USER_DATA_PENDING: "GET_USER_DATA_PENDING",
  GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
  GET_USER_DATA_FAILED: "GET_USER_DATA_FAILED",

  // Download Csv data
  GET_DOWNLOADCSV_DATA_PENDING: "GET_DOWNLOADCSV_DATA_PENDING",
  GET_DOWNLOADCSV_DATA_SUCCESS: "GET_DOWNLOADCSV_DATA_SUCCESS",
  GET_DOWNLOADCSV_DATA_FAILED: "GET_DOWNLOADCSV_DATA_FAILED",

  // Place data
  GET_PLACE_DATA_PENDING: "GET_PLACE_DATA_PENDING",
  GET_PLACE_DATA_SUCCESS: "GET_PLACE_DATA_SUCCESS",
  GET_PLACE_DATA_FAILED: "GET_PLACE_DATA_FAILED",

  //Place Detail
  GET_PLACE_DETAIL_PENDING: "GET_PLACE_DETAIL_PENDING",
  GET_PLACE_DETAIL_SUCCESS: "GET_PLACE_DETAIL_SUCCESS",
  GET_PLACE_DETAIL_FAILED: "GET_PLACE_DETAIL_FAILED",

  // Event List data
  GET_EVENT_DATA_PENDING: "GET_EVENT_DATA_PENDING",
  GET_EVENT_DATA_SUCCESS: "GET_EVENT_DATA_SUCCESS",
  GET_EVENT_DATA_FAILED: "GET_EVENT_DATA_FAILED",

  //Event Page data
  GET_EVENT_DETAIL_PENDING: "GET_EVENT_DETAIL_PENDING",
  GET_EVENT_DETAIL_SUCCESS: "GET_EVENT_DETAIL_SUCCESS",
  GET_EVENT_DETAIL_FAILED: "GET_EVENT_DETAIL_FAILED",

  // Query
  GET_QUERY_DATA_PENDING: "GET_QUERY_DATA_PENDING",
  GET_QUERY_DATA_SUCCESS: "GET_QUERY_DATA_SUCCESS",
  GET_QUERY_DATA_FAILED: "GET_QUERY_DATA_FAILED",

  //Query Page data
  GET_QUERY_DETAIL_PENDING: "GET_QUERY_DETAIL_PENDING",
  GET_QUERY_DETAIL_SUCCESS: "GET_QUERY_DETAIL_SUCCESS",
  GET_QUERY_DETAIL_FAILED: "GET_QUERY_DETAIL_FAILED",

  // Feedback
  GET_FEEDBACK_DATA_PENDING: "GET_FEEDBACK_DATA_PENDING",
  GET_FEEDBACK_DATA_SUCCESS: "GET_FEEDBACK_DATA_SUCCESS",
  GET_FEEDBACK_DATA_FAILED: "GET_FEEDBACK_DATA_FAILED",

  //Feedback Page data
  GET_FEEDBACK_DETAIL_PENDING: "GET_FEEDBACK_DETAIL_PENDING",
  GET_FEEDBACK_DETAIL_SUCCESS: "GET_FEEDBACK_DETAIL_SUCCESS",
  GET_FEEDBACK_DETAIL_FAILED: "GET_FEEDBACK_DETAIL_FAILED",

  // Accommodation
  GET_ACCOMMODATION_DATA_PENDING: "GET_ACCOMMODATION_DATA_PENDING",
  GET_ACCOMMODATION_DATA_SUCCESS: "GET_ACCOMMODATION_DATA_SUCCESS",
  GET_ACCOMMODATION_DATA_FAILED: "GET_ACCOMMODATION_DATA_FAILED",

  //Accommodation Page data
  GET_ACCOMMODATION_DETAIL_PENDING: "GET_ACCOMMODATION_DETAIL_PENDING",
  GET_ACCOMMODATION_DETAIL_SUCCESS: "GET_ACCOMMODATION_DETAIL_SUCCESS",
  GET_ACCOMMODATION_DETAIL_FAILED: "GET_ACCOMMODATION_DETAIL_FAILED",

  // Accommodation Category
  GET_ACCOMMODATION_CATEGORY_DATA_PENDING:
    "GET_ACCOMMODATION_CATEGORY_DATA_PENDING",
  GET_ACCOMMODATION_CATEGORY_DATA_SUCCESS:
    "GET_ACCOMMODATION_CATEGORY_DATA_SUCCESS",
  GET_ACCOMMODATION_CATEGORY_DATA_FAILED:
    "GET_ACCOMMODATION_CATEGORY_DATA_FAILED",

  // Admin
  GET_ADMIN_DATA_PENDING: "GET_ADMIN_DATA_PENDING",
  GET_ADMIN_DATA_SUCCESS: "GET_ADMIN_DATA_SUCCESS",
  GET_ADMIN_DATA_FAILED: "GET_ADMIN_DATA_FAILED",

  //Admin Page data
  GET_ADMIN_DETAIL_PENDING: "GET_ADMIN_DETAIL_PENDING",
  GET_ADMIN_DETAIL_SUCCESS: "GET_ADMIN_DETAIL_SUCCESS",
  GET_ADMIN_DETAIL_FAILED: "GET_ADMIN_DETAIL_FAILED",

  // Admin
  GET_PHOTOS_DATA_PENDING: "GET_PHOTOS_DATA_PENDING",
  GET_PHOTOS_DATA_SUCCESS: "GET_PHOTOS_DATA_SUCCESS",
  GET_PHOTOS_DATA_FAILED: "GET_PHOTOS_DATA_FAILED",

  //Admin Page data
  GET_PHOTOS_DETAIL_PENDING: "GET_PHOTOS_DETAIL_PENDING",
  GET_PHOTOS_DETAIL_SUCCESS: "GET_PHOTOS_DETAIL_SUCCESS",
  GET_PHOTOS_DETAIL_FAILED: "GET_PHOTOS_DETAIL_FAILED",

  // Activity
  GET_ACTIVITY_DATA_PENDING: "GET_ACTIVITY_DATA_PENDING",
  GET_ACTIVITY_DATA_SUCCESS: "GET_ACTIVITY_DATA_SUCCESS",
  GET_ACTIVITY_DATA_FAILED: "GET_ACTIVITY_DATA_FAILED",

  //Admin Page data
  GET_ACTIVITY_DETAIL_PENDING: "GET_ACTIVITY_DETAIL_PENDING",
  GET_ACTIVITY_DETAIL_SUCCESS: "GET_ACTIVITY_DETAIL_SUCCESS",
  GET_ACTIVITY_DETAIL_FAILED: "GET_ACTIVITY_DETAIL_FAILED",

  // Admin
  GET_FOOD_DATA_PENDING: "GET_FOOD_DATA_PENDING",
  GET_FOOD_DATA_SUCCESS: "GET_FOOD_DATA_SUCCESS",
  GET_FOOD_DATA_FAILED: "GET_FOOD_DATA_FAILED",

  //Admin Page data
  GET_FOOD_DETAIL_PENDING: "GET_FOOD_DETAIL_PENDING",
  GET_FOOD_DETAIL_SUCCESS: "GET_FOOD_DETAIL_SUCCESS",
  GET_FOOD_DETAIL_FAILED: "GET_FOOD_DETAIL_FAILED",

  //Banner
  GET_BANNER_DETAIL_PENDING: "GET_BANNER_DETAIL_PENDING",
  GET_BANNER_DETAIL_SUCCESS: "GET_BANNER_DETAIL_SUCCESS",
  GET_BANNER_DETAIL_FAILED: "GET_BANNER_DETAIL_FAILED",

  //Shop
  GET_SHOP_DETAIL_PENDING: "GET_SHOP_DETAIL_PENDING",
  GET_SHOP_DETAIL_SUCCESS: "GET_SHOP_DETAIL_SUCCESS",
  GET_SHOP_DETAIL_FAILED: "GET_SHOP_DETAIL_FAILED",

  // change Csv data
  SET_DOWNLOADCSV_INITIAL: "SET_DOWNLOADCSV_INITIAL",
};
