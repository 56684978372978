import { adminTypes } from "app/redux/type/adminTypes";

const initialState = {
  isLoading: false,
  accList: null,
  accDetails: null,
  catList: null,
  msg: "",
};
export const accommodationReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_ACCOMMODATION_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_ACCOMMODATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        accList: action.payload,
        msg: "",
      };
    case adminTypes.GET_ACCOMMODATION_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        accList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_ACCOMMODATION_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_ACCOMMODATION_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        accDetails: action.payload,
        msg: "",
      };
    case adminTypes.GET_ACCOMMODATION_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        accDetails: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_ACCOMMODATION_CATEGORY_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_ACCOMMODATION_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        catList: action.payload,
        msg: "",
      };
    case adminTypes.GET_ACCOMMODATION_CATEGORY_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        catList: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};
