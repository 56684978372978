import { adminTypes } from "app/redux/type/adminTypes";

const initialState = {
  isLoading: false,
  cmsList: null,
  cmsDetail: null,
  msg: "",
};
export const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_CMS_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_CMS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        cmsList: action.payload,
        msg: "",
      };
    case adminTypes.GET_CMS_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        cmsList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_PAGE_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_PAGE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        cmsDetail: action.payload,
        msg: "",
      };
    case adminTypes.GET_PAGE_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        cmsDetail: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};
