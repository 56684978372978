import { adminTypes } from "app/redux/type/adminTypes";

const initialState = {
  isLoading: false,
  placeList: null,
  placeDetail: null,
  activityDeytail: null,
  photoDetail: null,
  msg: "",
};
export const placeReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_PLACE_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_PLACE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        placeList: action.payload,
        msg: "",
      };
    case adminTypes.GET_PLACE_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        placeList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_PLACE_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_PLACE_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        placeDetail: action.payload,
        msg: "",
      };
    case adminTypes.GET_PLACE_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        placeDetail: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_PHOTOS_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_PHOTOS_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        photoDetail: action.payload,
        msg: "",
      };
    case adminTypes.GET_PHOTOS_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        photoDetail: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_ACTIVITY_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_ACTIVITY_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        activityDeytail: action.payload,
        msg: "",
      };
    case adminTypes.GET_ACTIVITY_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        activityDeytail: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};
