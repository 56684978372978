// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
figure,
p {
  margin: 0;
  padding: 0;
}

body {
  padding: 0;
  padding-right: 0 !important;
}

.commonSelectWrpper .labelClassTextfield {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;;;;;;;;;;EAUE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\nul,\nol,\nfigure,\np {\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  padding: 0;\n  padding-right: 0 !important;\n}\n\n.commonSelectWrpper .labelClassTextfield {\n  margin-bottom: 10px;\n  font-weight: 500;\n  font-size: 16px;\n  color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
