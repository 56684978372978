import { adminTypes } from "app/redux/type/adminTypes";

const initialState = {
  isLoading: false,
  eventList: null,
  eventDetails: null,
  msg: "",
  downloadCsv: null,
};
export const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_EVENT_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_EVENT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        eventList: action.payload,
        msg: "",
      };
    case adminTypes.GET_EVENT_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        eventList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_EVENT_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        eventDetails: action.payload,
        msg: "",
      };
    case adminTypes.GET_EVENT_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        eventDetails: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};
