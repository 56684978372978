import { adminTypes } from "app/redux/type/adminTypes";

const initialState = {
  isLoading: false,
  userList: null,
  userDetail: null,
  msg: "",
  downloadCsv: null,
};
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_USER_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        userList: action.payload,
        msg: "",
      };
    case adminTypes.GET_USER_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        userList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_DOWNLOADCSV_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_DOWNLOADCSV_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        downloadCsv: action.payload,
        msg: "",
      };
    case adminTypes.GET_DOWNLOADCSV_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        downloadCsv: action.payload,
        msg: action.msg,
      };

    case adminTypes.SET_DOWNLOADCSV_INITIAL:
      return {
        ...state,
        downloadCsv: action.payload,
      };

    default:
      return state;
  }
};
