import { adminTypes } from "app/redux/type/adminTypes";

const initialState = {
  isLoading: false,
  feedbackList: null,
  feedbackDetails: null,
  msg: "",
};
export const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_FEEDBACK_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_FEEDBACK_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        feedbackList: action.payload,
        msg: "",
      };
    case adminTypes.GET_FEEDBACK_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        feedbackList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_FEEDBACK_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_FEEDBACK_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        feedbackDetails: action.payload,
        msg: "",
      };
    case adminTypes.GET_FEEDBACK_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        feedbackDetails: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};
